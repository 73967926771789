// index.js
import Vue from 'vue'
import App from './app.vue'
import router from './router'
import 'animate.css'
import VueVideoPlayer from 'vue-video-player'

// require videojs style
import 'video.js/dist/video-js.css'

Vue.use(VueVideoPlayer, /* {
    options: global default options,
    events: global videojs events
  } */)

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})